<template>
  <UForm
    ref="formRef"
    :state="order"
    :validate="validate"
    @submit="submit"
  >
    <UTabs
      :items="tabItems"
      v-model="tabsIndex"
      name="type"
      class="max-w-full p-5 mx-auto w-96"
    >
      <template #item="{ item }: { item: TabItem }">
        <PlaceAutocompleteInput
          v-model="order.from"
          :label="item.key === 'oneway' ? 'From' : 'Pickup'"
          placeholder="Enter a starting point"
          class="mt-5"
          name="from"
        />

        <PlaceAutocompleteInput
          v-if="item.key === 'oneway'"
          v-model="order.to"
          label="To"
          name="to"
          placeholder="Enter a destination point"
        />
        <UFormGroup
          v-else-if="item.key === 'hourly'"
          size="xl"
          :label="`For ${order.duration} hours`"
          name="duration"
        >
          <URange v-model="order.duration" :max="12" />
        </UFormGroup>
        <UFormGroup
          v-else-if="item.key === 'daily'"
          size="xl"
          :label="`For ${order.durationDays} days`"
          name="durationDays"
        >
          <URange v-model="order.durationDays" :min="1" :max="10" />
        </UFormGroup>

        <UFormGroup name="common" />

        <div class="flex flex-wrap items-center justify-between">
          <div>{{ order.type === 'oneway' && order.distance ? formatDistance(order.distance) : '' }}</div>
          <UButton
            type="submit"
            :label="classParam ? 'Details' : 'Get a price and book'"
            size="xl"
            trailing-icon="i-heroicons-arrow-right-20-solid"
          />
        </div>

        <p class="mt-4 mb-0 text-sm prose text-right">Need something special? <NuxtLink to="/services/customized-trip">Customized trips</NuxtLink></p>
      </template>
    </UTabs>
  </UForm>
</template>

<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router'
import { FetchError } from 'ofetch'
import type { Form, FormError } from '#ui/types'


const order = reactiveWithDefaults(useOrder(), {
  type: 'oneway',
  duration: 3,
  durationDays: 1,
})


const formRef = ref<Form<FormError[]>>()

type TabItem = { key: typeof order.type, label: string }
const tabItems: TabItem[] = [
  { key: 'oneway', label: 'One way' },
  { key: 'hourly', label: 'Hourly' },
  { key: 'daily', label: 'Daily' },
]

const tabsIndex = computed({
  get: () => tabItems.findIndex(i => i.key === order.type),
  set: index => order!.type = tabItems[index].key,
})


function validate (): FormError[] {
  const errors: FormError[] = []

  if (!order.from) {
    errors.push({ path: 'from', message: 'Departure place is required' })
  }

  if (order.from && !isPlaceSpecificEnough(order.from)) {
    errors.push({ path: 'from', message: 'Please select a more specific location, such as hotel name, airport or exact address.' })
  }

  if (order.type === 'oneway') {
    if (!order.to) {
      errors.push({ path: 'to', message: 'Destination place is required' })
    }

    if (order.to && !isPlaceSpecificEnough(order.to)) {
      errors.push({ path: 'to', message: 'Please select a more specific location, such as hotel name, airport or exact address.' })
    }

    if (order.from && order.to && !isPlaceAllowedBoundaries(order.from) && !isPlaceAllowedBoundaries(order.to)) {
      errors.push({ path: 'common', message: 'We drive only from or to Prague.' })
    }
  }
  else if (order.type === 'hourly') {
    if (order.duration !== undefined && order.duration < 3) {
      errors.push({ path: 'duration', message: '3 hours are minimum duration.' })
    }

    if (order.from && !isPlaceAllowedBoundaries(order.from)) {
      errors.push({ path: 'common', message: 'We drive only from or to Prague.' })
    }
  }
  else if (order.type === 'daily') {
    if (order.from && !isPlaceAllowedBoundaries(order.from)) {
      errors.push({ path: 'common', message: 'We drive only from or to Prague.' })
    }
  }

  console.log('order validate', errors, {...order})
  return errors
}


/**
 * Calculate distance between two places
 */
watch(() => [order.from, order.to, order.type], async (_, old) => {
  // SSR or not hydrated yet
  if (import.meta.server || !order.type)
    return

  // Return if form has errors
  if (formRef.value!.errors.value?.length)
    return

  // Already filled and is initial
  if (order.distance && !old[0] && !old[1])
    return


  if (order.type === 'oneway' && order.from && order.to) {
    try {
      const { distanceMeters } = await $fetch('/api/maps/route', { query: {
        originPlaceId: order.from.placeId,
        destinationPlaceId: order.to.placeId,
      }})
      order.distance = distanceMeters / 1000
      if (order.distance === undefined) {
        throw new Error('Distance is undefined')
      }
      if (order.distance < 1 || isNaN(order.distance)) {
        order.distance = undefined
        formRef.value!.setErrors([...formRef.value!.getErrors(), { path: 'common', message: 'Distance is less then 1 km.' }])
      }
    } catch (error) {
      if (error instanceof FetchError && error.response?.status === 400) {
        order.distance = undefined
        formRef.value!.setErrors([...formRef.value!.getErrors(), { path: 'common', message: 'Cannot find route between these places. If you belive they are reachable by car contact us, please.' }])
      }
      console.log('Fetch error:', error)
      formRef.value!.setErrors([...formRef.value!.getErrors(), { path: 'common', message: 'Route calculation failed. Contact us, please.' }])
    }
  }
  else {
    order.distance = undefined
  }
}, { deep: true })


const $router = useRouter()
const classParam = useRouteQuery('class')


async function submit () {
  await formRef.value!.validate()
  if (typeof classParam.value === 'string' && useCarClasses().find(c => c.id === classParam.value)) {
    order.carClass = classParam.value
    $router.push('/book/details')
  }
  else {
    $router.push('/book/car')
  }
}
</script>
