<template>
  <UFormGroup size="xl" :label="label">
    <USelectMenu
      :placeholder="placeholder"
      :searchable="suggest"
      :multiple="false"
      :ui-menu="{ option: { container: 'w-full' } }"
      @change="onChange"
    >
      <template #label v-if="modelValue">
        <div class="truncate">
          <span class="font-bold">{{ modelValue.mainText }}&nbsp;</span>
          <span class="text-sm">{{ modelValue.secondaryText }}</span>
        </div>
      </template>
      <template #option="{ option: { placePrediction } }: { option: protos.google.maps.places.v1.AutocompletePlacesResponse.ISuggestion }">
        <div class="grow">
          <div class="font-bold">{{ placePrediction!.structuredFormat!.mainText!.text }}</div>
          <div class="text-sm truncate"> {{ placePrediction!.structuredFormat?.secondaryText!.text }}</div>
        </div>
      </template>
    </USelectMenu>
  </UFormGroup>
</template>

<script setup lang="ts">
import { v4 as uuid } from 'uuid'
import type { OrderPlace } from '~/composables/order'
import type { protos } from '@googlemaps/places'


const props = defineProps<{
  label: string
  placeholder: string
  modelValue?: OrderPlace
  origin?: { lat: number, lng: number }
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value: OrderPlace | undefined): void,
}>()

// Saved value could have old schema (without location), delete it
if (props.modelValue && !props.modelValue.location) {
  emit('update:modelValue', undefined)
}

let sessionToken = uuid()

async function suggest (input: string) {
  if (!input) return [] // TODO: Saved last selected

  try {
    const { suggestions } = await $fetch<protos.google.maps.places.v1.IAutocompletePlacesResponse>('/api/maps/autocomplete', { query: {
      input,
      sessionToken,
      language: 'en',
    } })
    if (!Array.isArray(suggestions))
      throw new Error('Invalid autocomplete response - suggestions not an array')

    return suggestions
  } catch (error) {
    console.error(error)
    return []
  }
}

const { emitFormChange } = useFormGroup()

async function onChange (value: protos.google.maps.places.v1.AutocompletePlacesResponse.ISuggestion) {
  const data = await $fetch<OrderPlace>('/api/maps/place', { query: {
    placeId: value.placePrediction!.placeId,
    sessionToken,
  }})

  emit('update:modelValue', data)
  emitFormChange()
}
</script>
